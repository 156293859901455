import React from 'react';
import {
    Col,
    Row,
} from 'reactstrap';

const { REACT_APP_WEBSITE_HOME } = process.env;

const DefaultFooter = () => (
    <>
        <Row className="w-100 my-1">
            <Col className="col-md-7 col-12">
                Viewber Ltd, Ham Hill, Sydmonton, Newbury, Berkshire, RG20 9AB, Tel: 03330 124186
                <br />
                <a
                    href={`${REACT_APP_WEBSITE_HOME}/contact-us`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Email Us
                </a>
            </Col>
            <Col className="col-md-5 col-12 text-md-right">
                Copyright,
                {' '}
                <a
                    href={REACT_APP_WEBSITE_HOME}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Viewber
                </a>
                {' '}
                &copy; {(new Date()).getFullYear()}
                {' '}
                Company No: 10069752
                <br />
                <a
                    href={`${REACT_APP_WEBSITE_HOME}/site-info`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Site Info
                </a>
                {' / '}
                <a
                    href={`${REACT_APP_WEBSITE_HOME}/privacy-policy`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Privacy Policy
                </a>
            </Col>
        </Row>
    </>
);

export default DefaultFooter;
