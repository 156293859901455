import React from 'react';
import * as yup from 'yup';

export const resourcesName = 'Users';

export const keyField = 'id';

export const nameField = 'name';

export const columns = [
    {
        dataField: 'id',
        text: 'ID',
        className: 'col-md-12',
    },
    {
        dataField: 'name',
        text: 'Name',
        className: 'col-md-5 col-12',
    },
    {
        dataField: 'email',
        text: 'Email',
        className: 'col-md-5 col-12',
    },
    {
        dataField: 'role.display_name',
        text: 'Role',
        className: 'col-md-2 col-12',
    },
    {
        dataField: 'created_at',
        text: 'Registered',
        className: 'col-md-12',
    }
];

export const searchColumns = [
    'Name',
    'Email Address',
];

export const canDestroy = true;

export const pageSize = 10;

export const schema = {
    billing_type_id: {
        label: 'Billing type',
        type: 'select',
        value: '',
        rules: yup.number()
            .integer()
            .required(),
        errors: [],
        empty_option: 'Please select how you would prefer to receive your billing...',
        values: [
            // values get imported from API...
        ],
    },
    city: {
        type: 'text',
        value: '',
        rules: yup.string()
            .required()
            .max(255),
        errors: [],
    },
    company: {
        type: 'text',
        value: '',
        rules: yup.string()
            .nullable()
            .max(255),
        errors: [],
    },
    email: {
        type: 'email',
        value: '',
        // Users can not update their email address
        disabled: true,
        formText: (
            <>
                <a href="mailto:community@viewber.co.uk?subject=I want to change my email address">
                    <i className="fa fa-fw fa-envelope"></i>
                    {' '}
                    I want to change my email address
                </a>
            </>
        ),
        rules: yup.string()
            .required()
            .email()
            .max(255),
        errors: [],
    },
    emergency_contact_name: {
        type: 'text',
        value: '',
        label: 'Emergency Contact Name',
        rules: yup.string()
            .required()
            .max(255),
        errors: [],
    },
    emergency_contact_telephone: {
        type: 'tel',
        value: '',
        label: 'Emergency Contact Telephone',
        rules: yup.string()
            .required()
            .max(255),
        errors: [],
    },
    first_name: {
        type: 'text',
        value: '',
        disabled: true,
        rules: yup.string()
            .required()
            .max(255),
        errors: [],
    },
    landline: {
        type: 'tel',
        value: '',
        rules: yup.string()
            .nullable()
            .max(255),
        errors: [],
    },
    last_name: {
        type: 'text',
        value: '',
        disabled: true,
        formText: (
            <>
                <a href="mailto:registrations@viewber.co.uk?subject=I want to change my name">
                    <i className="fa fa-fw fa-envelope"></i>
                    {' '}
                    I want to change my name
                </a>
            </>
        ),
        rules: yup.string()
            .required()
            .max(255),
        errors: [],
    },
    line_1: {
        type: 'text',
        value: '',
        rules: yup.string()
            .required()
            .max(255),
        errors: []
    },
    line_2: {
        type: 'text',
        value: '',
        rules: yup.string()
            .nullable()
            .max(255),
        errors: []
    },
    max_distance: {
        type: 'number',
        value: '',
        rules: yup.number()
            .integer()
            .nullable(),
        errors: [],
    },
    no_landline: {
        type: 'checkbox',
        value: 0,
        rules: yup.number()
            .integer()
            .required(),
        errors: [],
    },
    current_password: {
        label: "Current Password",
        placeholder: "Current Password",
        type: 'password',
        value: '',
        rules: yup.string()
            .min(4)
            .max(255),
        errors: [],
    },
    password: {
        type: 'password',
        value: '',
        rules: yup.string()
            .required()
            .min(6)
            .max(255),
        errors: [],
    },
    mfa_enabled: {
        type: 'checkbox',
        rules: undefined,
        value: 0,
        errors: [],
        label: 'Turn on multi-factor authentication',
    },
    password_confirmation: {
        label: "Confirm Password",
        placeholder: "Confirm your password",
        type: 'password',
        value: '',
        rules: yup.string()
            .required()
            .min(6)
            .max(255),
        errors: [],
    },
    phone: {
        type: 'tel',
        value: '',
        rules: yup.string()
            .nullable()
            .max(100),
        errors: [],
    },
    postcode: {
        type: 'text',
        value: '',
        rules: yup.string()
            .required()
            .max(50),
        errors: [],
    },
    vat: {
        label: "VAT",
        placeholder: "Enter your VAT number",
        type: 'text',
        value: '',
        rules: yup.string()
            .nullable()
            .max(50),
        errors: [],
    },
};

export default schema;
