import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Container } from 'reactstrap';
import {
    AppBreadcrumb,
    AppFooter,
    AppHeader,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarMinimizer,
    AppSidebarNav
} from '@coreui/react';
import { Page404 } from '../../views/Pages';
import ErrorBoundary from '../../ErrorBoundary';
import MaintenanceChecker from '../../MaintenanceChecker';
import LoggingOutCard from '../../components/Cards/LoggingOutCard';
import {endsWith} from '../../helpers/string';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import DocumentsReadRestrictedComponent from '../../components/DocumentsReadRestrictedComponent';
import CalendarUpdateRestrictedComponent from '../../components/CalendarUpdateRestrictedComponent';
import RestrictedComponent from '../../components/RestrictedComponent';
import UserNotApprovedRestrictedComponent from '../../components/UserNotApprovedRestrictedComponent';
import WaitingComponent from '../../components/WaitingComponent';

// sidebar nav config
// routes config
import routes from '../../routes';
import MissingHmrcDetails from "../../components/MissingHmrcDetails";

class DefaultLayout extends Component {

    render() {
        const {
            history,
            location,
            logging_out,
            user
        } = this.props;

        const {
            REACT_APP_INVITATION_NOTIFICATION_TARGET_TYPE_ID,
            REACT_APP_APPOINTMENT_NOTIFICATION_TARGET_TYPE_ID,
            REACT_APP_INVOICE_NOTIFICATION_TARGET_TYPE_ID,
            REACT_APP_USER_NOTIFICATION_TARGET_TYPE_ID,
            REACT_APP_DOCUMENT_NOTIFICATION_TARGET_TYPE_ID,
            REACT_APP_FILE_UPLOAD_BASE_URL,
        } = process.env;

        // Extract Viewber type IDs from the user object
        const viewberTypeIds = user?.viewber_types?.map(({ id }) => id) || [];

        // Define specific Viewber skills and DEA member categories
        const VIEWBER_SKILLS = [62, 63, 80]; // Professional Photographer, Key Safe Fitter, Property Marketing Packs
        const DEA_MEMBER_CATEGORIES = [2, 3, 4, 5]; // DEA Member Categories

        // Determine if the academy should be hidden
        const hideAcademyByViewberTypes = VIEWBER_SKILLS.some(skill => viewberTypeIds.includes(skill));
        const hideAcademyByMemberDefaultCategory = DEA_MEMBER_CATEGORIES.includes(user?.viewber_category_id);

        const hideAcademy = hideAcademyByViewberTypes || hideAcademyByMemberDefaultCategory

        const openAffiliatesLink = () => {
            window.open('https://viewber.co.uk/about-viewber/affiliates', '_blank');
        };

        const navigation = {
            items: [
                {
                    name: 'Dashboard',
                    url: '/dashboard',
                    icon: 'fa fa-dashboard',
                },
                {
                    divider: true,
                },
                {
                    name: 'Invitations',
                    url: '/invitations',
                    icon: 'fa fa-paper-plane',
                    notification_target_type_id: parseInt(
                        REACT_APP_INVITATION_NOTIFICATION_TARGET_TYPE_ID,
                        10
                    ),
                },
                {
                    name: 'Appointments',
                    url: '/appointments',
                    icon: 'fa fa-clock-o',
                    notification_target_type_id: parseInt(
                        REACT_APP_APPOINTMENT_NOTIFICATION_TARGET_TYPE_ID,
                        10
                    ),
                },
                {
                    name: 'My Calendar',
                    url: '/calendar',
                    icon: 'fa fa-calendar',
                    attributes: {
                        onClick: (e) => {
                            e.preventDefault();

                            e.currentTarget.parentElement.classList.toggle('open');

                            history.push('/calendar');
                        }
                    },
                    children: [
                        {
                            name: 'Integration',
                            url: '/calendar/integration',
                            icon: 'fa fa-calendar-o',
                            attributes: {id: "external-calendar" },
                        },
                    ]
                },
                {
                    name: 'Invoices',
                    url: '/invoices',
                    icon: 'fa fa-gbp',
                    notification_target_type_id: parseInt(
                        REACT_APP_INVOICE_NOTIFICATION_TARGET_TYPE_ID,
                        10
                    ),
                },
                {
                    name: 'Profile',
                    icon: 'fa fa-user-o',
                    notification_target_type_id: parseInt(
                        REACT_APP_USER_NOTIFICATION_TARGET_TYPE_ID,
                        10
                    ),
                    children: [
                        {
                            name: 'Edit Profile',
                            url: '/me/edit',
                            icon: 'fa fa-pencil',
                        },
                        {
                            name: 'Keys Held',
                            url: '/me/keys-held',
                            icon: 'fa fa-key',
                        },
                        {
                            name: 'Address',
                            url: '/me/address',
                            icon: 'fa fa-map-marker',
                        },
                        {
                            name: 'Billing Settings',
                            url: '/me/billing',
                            icon: 'fa fa-money',
                        },
                        {
                            name: 'Change Password',
                            url: '/me/password',
                            icon: 'fa fa-key',
                        },
                        {
                            name: 'Security Settings',
                            url: '/me/security',
                            icon: 'fa fa-cog',
                        },
                        {
                            name: 'Additional Details',
                            url: '/me/additional-details',
                            icon: 'fa fa-list',
                        },
                        {
                            name: 'Academy',
                            url: '/me/academy',
                            icon: 'fa fa-graduation-cap',
                            class: hideAcademy ? 'd-none' : '',
                        },
                    ]
                },
                {
                    name: 'Documents',
                    icon: 'fa fa-file',
                    notification_target_type_id: parseInt(
                        REACT_APP_DOCUMENT_NOTIFICATION_TARGET_TYPE_ID,
                        10
                    ),
                    children: [
                        {
                            name: 'Viewber Manual',
                            url: '/viewbers-manual',
                            icon: 'fa fa-file',
                        },
                        {
                            name: 'Membership Agreement',
                            url: '/membership-agreement',
                            icon: 'fa fa-file',
                        },
                        {
                            name: 'Guidance',
                            url: '/guidance',
                            icon: 'fa fa-file',
                        },
                        {
                            name: 'Video Hazard Awareness Training',
                            url: 'https://vimeo.com/833686829/00e0418f7a',
                            icon: 'fa fa-file',
                        },
                        {
                            name: 'Privacy Policy',
                            url: endsWith(REACT_APP_FILE_UPLOAD_BASE_URL, '/')
                                ? REACT_APP_FILE_UPLOAD_BASE_URL + 'userfiles/documents/Viewber Limited GDPR Privacy Notice for Viewber Members March 2024.pdf'
                                : REACT_APP_FILE_UPLOAD_BASE_URL + '/userfiles/documents/Viewber Limited GDPR Privacy Notice for Viewber Members March 2024.pdf',
                            icon: 'fa fa-file',
                        },
                        {
                            name: 'Hazard and Risk Assessment for Property Associated Risks',
                            url: '/hazard-and-risk-assessment-for-property-associated-risks',
                            icon: 'fa fa-file',
                        },
                        {
                            name: 'Lone Worker Policy',
                            url: '/lone-worker-policy',
                            icon: 'fa fa-file',
                        },
                        {
                            name: 'Health & Safety Policy',
                            url: '/health-and-safety',
                            icon: 'fa fa-file',
                        },
                    ],
                },
                {
                    name: 'Wellbeing Support',
                    url: '/wellbeing-support',
                    icon: 'fa fa-info',
                },
            ]
        }

        return (
            <div className={`app container-${user?.id}`}>
                <AppHeader fixed>
                    <DefaultHeader history={history} />
                </AppHeader>
                <div className="app-body">
                    <AppSidebar fixed display="lg">
                        <AppSidebarHeader />
                            <AppSidebarForm />
                            <AppSidebarNav navConfig={navigation} {...(({ dispatch, ...o }) => o)(this.props)} />
                            <AppSidebarFooter />
                        <AppSidebarMinimizer />
                    </AppSidebar>
                    <main className="main">
                        <div style={{position: 'relative'}}>
                            <AppBreadcrumb appRoutes={routes}/>
                            <button
                              className="btn btn-sm btn-warning"
                              style={{marginLeft: 'auto', position: 'absolute', right: '1rem', top: '0.55rem'}}
                              onClick={() => openAffiliatesLink()}
                            >
                                Refer a Client
                            </button>
                        </div>
                        <Container fluid>
                            {
                              user?.application_status_id === 4
                              && user?.missingHmrcDetails?.length > 0
                              && <MissingHmrcDetails missingDetails={user?.missingHmrcDetails} />
                            }
                            <LoggingOutCard loggingOut={logging_out} />
                            <Switch>
                                {
                                    routes.map((route, idx) => {
                                        return route.component
                                            ? (
                                                <Route
                                                    key={idx}
                                                    path={route.path}
                                                    exact={route.exact}
                                                    name={route.name}
                                                    render={props => (
                                                        <ErrorBoundary>
                                                            <RestrictedComponent>
                                                                <MaintenanceChecker>
                                                                    <UserNotApprovedRestrictedComponent>
                                                                        <DocumentsReadRestrictedComponent>
                                                                            <CalendarUpdateRestrictedComponent>
                                                                                <WaitingComponent
                                                                                    Component={route.component}
                                                                                    query={queryString.parse(location.search)}
                                                                                    {...props}
                                                                                />
                                                                            </CalendarUpdateRestrictedComponent>
                                                                        </DocumentsReadRestrictedComponent>
                                                                    </UserNotApprovedRestrictedComponent>
                                                                </MaintenanceChecker>
                                                            </RestrictedComponent>
                                                        </ErrorBoundary>
                                                    )}
                                                />
                                            )
                                            : null;
                                    })
                                }
                                <Route name="Page 404" component={Page404} />
                            </Switch>
                        </Container>
                    </main>
                </div>
                <AppFooter>
                    <DefaultFooter />
                </AppFooter>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { logging_out, user } = state.auth;
    const { not_read_amounts } = state.notifications;

    return {
        logging_out: logging_out === true ? true : undefined,
        not_read_notification_amounts: not_read_amounts,
        user
    };
};

export default connect(
    mapStateToProps
)(DefaultLayout);
