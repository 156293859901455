import axios from '../axios';
import isNil from 'lodash/isNil';
import { apiRequestWithBaseUrl } from "../apiRequest";

export const find = ({ id, token }) => apiRequestWithBaseUrl(`appointments/${id}`, {
    method: 'GET',
    token
});

export const findNext = ({ token }) => apiRequestWithBaseUrl(`appointments/next`, {
    method: 'GET',
    token
});

export const update = ({ id, token, ...rest }) => apiRequestWithBaseUrl(`appointments/${id}`, {
    method: 'PUT',
    token,
    data: rest
});

export const paginate = async ({
	token,
    page,
    pageSize,
	q,
    ...rest
}) => apiRequestWithBaseUrl('appointments/paginate', {
	token,
	params: {
		page,
		page_size: pageSize,
		q: !isNil(q) ? q : undefined,
		...rest,
	}
});

export const dateFilter = async ({
	token,
	start,
	end
}) => apiRequestWithBaseUrl('appointments/date-filter', {
    method: 'GET',
	token,
	params: {
		start,
		end
	}
});

export const acceptChange = ({ id, token }) => apiRequestWithBaseUrl(`appointments/${id}/accept-change`, {
    method: 'POST',
    token
});

export const rejectChange = ({ id, token, ...rest }) => apiRequestWithBaseUrl(`appointments/${id}/reject-change`, {
    method: 'POST',
    token,
    data: rest
});

export const keysNotReceived = ({ id, token }) => apiRequestWithBaseUrl(`appointments/${id}/keys-not-received`, {
    method: 'POST',
    token
});

export const keyholderRejectChange = ({ id, token, ...rest }) => apiRequestWithBaseUrl(`appointments/${id}/reject-change-keyholder`, {
    method: 'POST',
    token,
    data: rest
});

export const reportNotes = ({ id, token, ...rest }) => apiRequestWithBaseUrl(`appointments/${id}/report-notes`, {
    method: 'POST',
    token,
    data: rest
});

export const feedback = ({ id, token, ...rest }) => apiRequestWithBaseUrl(`appointments/${id}/feedback`, {
    method: 'POST',
    token,
    data: rest
});

export const documents = ({ id, token }) => apiRequestWithBaseUrl(`appointments/${id}/get-documents`, {
    method: 'GET',
    token
});

export const propertyDocuments = ({ id, token }) => apiRequestWithBaseUrl(`appointments/${id}/property-documents`, {
    method: 'GET',
    token
});

export const feedbackAnswers = ({ id, token }) => apiRequestWithBaseUrl(`appointments/${id}/feedback-answers`, {
    method: 'GET',
    token
});

export const feedbackImages = ({ id, token }) => apiRequestWithBaseUrl(`appointments/${id}/feedback-images`, {
    method: 'GET',
    token
});

export const feedbackQuestions = ({ id, token }) => apiRequestWithBaseUrl(`appointments/${id}/feedback-questions`, {
    method: 'GET',
    token
});

export const feedbackNoShowQuestions = ({ id, token }) => apiRequestWithBaseUrl(`appointments/${id}/feedback-questions-no-show`, {
    method: 'GET',
    token
});

export const feedbackOverrun = ({ id, token }) => apiRequestWithBaseUrl(`appointments/${id}/feedback-overrun`, {
    method: 'GET',
    token
});

export const uploadFeedbackImage = async data => {
    const formData = new FormData();
    
    Object.entries(data).forEach(([name, value]) => {
        formData.append(name, value);
    });

    return axios.post(
        process.env.REACT_APP_FILE_UPLOAD_HANDLER,
        formData
    );
};

export const invoiceable = ({ token }) => apiRequestWithBaseUrl(`appointments/user-invoiceable`, {
    method: 'GET',
    token
});

export const cancel = ({ id, token, cancelled_reason, alternative_times, all_day_options }) => apiRequestWithBaseUrl(`appointments/${id}/cancel`, {
    method: 'POST',
    token,
    data: {
        cancelled_reason,
        alternative_times,
        all_day_options,
    }
});

export const destroy = ({ id, token }) => apiRequestWithBaseUrl(`appointments/${id}`, {
    method: 'DELETE',
    token
});

export const feedbackKeyHolding = ({ id, token }) => apiRequestWithBaseUrl(`appointments/${id}/feedback-key-holding`, {
    method: 'GET',
    token
});

export const feedbackKeyHoldingSubmit = ({ id, token, ...rest }) => apiRequestWithBaseUrl(`appointments/${id}/feedback-key-holding`, {
    method: 'POST',
    token,
    data: rest
});

export const multiBookingSameViewberDetails = ({ id, token }) => apiRequestWithBaseUrl(`appointments/${id}/multi-booking-same-viewber-details`, {
    method: 'GET',
    token,
});

export const madeSnappyDetails = ({ id, token }) => apiRequestWithBaseUrl(`appointments/${id}/made-snappy`, {
    method: 'GET',
    token,
});

export const updateMadeSnappyCamera = ({ id, token, ...rest }) => apiRequestWithBaseUrl(`appointments/${id}/made-snappy/camera`, {
    method: 'PATCH',
    token,
    data: rest,
});

export const showMadeSnappyProperty = ({ id, token }) => apiRequestWithBaseUrl(`appointments/${id}/made-snappy/property`, {
    method: 'GET',
    token,
    params: rest,
});

export const createMadeSnappyProperty = ({ id, token, ...rest }) => apiRequestWithBaseUrl(`appointments/${id}/made-snappy/property`, {
    method: 'POST',
    token,
    data: rest,
});

