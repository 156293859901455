import { getFullDateText, getTimeText } from './dates';
import { viewingOccursOnDateAndTime } from "./viewings";

const {
    REACT_APP_APPOINTMENT_NOTIFICATION_TARGET_TYPE_ID,
    REACT_APP_DOCUMENT_NOTIFICATION_TARGET_TYPE_ID,
    REACT_APP_INVITATION_NOTIFICATION_TARGET_TYPE_ID,
    REACT_APP_INVOICE_NOTIFICATION_TARGET_TYPE_ID,
    REACT_APP_USER_NOTIFICATION_TARGET_TYPE_ID,
    REACT_APP_SMS_MESSAGE_NOTIFICATION_TARGET_TYPE_ID,
    REACT_APP_FEEDBACK_30_APPOINTMENT_TARGET_SUB_TYPE_ID,
    REACT_APP_FEEDBACK_60_APPOINTMENT_TARGET_SUB_TYPE_ID,
    REACT_APP_FEEDBACK_120_APPOINTMENT_TARGET_SUB_TYPE_ID,
    REACT_APP_FEEDBACK_240_APPOINTMENT_TARGET_SUB_TYPE_ID,
    REACT_APP_CHANGED_APPOINTMENT_TARGET_SUB_TYPE_ID,
    REACT_APP_VETTED_USER_NOTIFICATION_TARGET_TYPE_ID,
} = process.env;

const feedbackTargetSubTypes =[
    parseInt(REACT_APP_FEEDBACK_30_APPOINTMENT_TARGET_SUB_TYPE_ID, 10),
    parseInt(REACT_APP_FEEDBACK_60_APPOINTMENT_TARGET_SUB_TYPE_ID, 10),
    parseInt(REACT_APP_FEEDBACK_120_APPOINTMENT_TARGET_SUB_TYPE_ID, 10),
    parseInt(REACT_APP_FEEDBACK_240_APPOINTMENT_TARGET_SUB_TYPE_ID, 10),
];

export const getNotificationIconClassName = notification => {
    if(
        notification
        && notification.target_type
    ) {
        const { target_type, target_sub_type } = notification;

        if (
            target_type.id === parseInt(REACT_APP_INVITATION_NOTIFICATION_TARGET_TYPE_ID, 10)
            || (target_sub_type && target_sub_type.id === parseInt(REACT_APP_SMS_MESSAGE_NOTIFICATION_TARGET_TYPE_ID, 10))
        ) {
            return 'fa-paper-plane';
        }

        else if(
            target_type.id === parseInt(REACT_APP_APPOINTMENT_NOTIFICATION_TARGET_TYPE_ID, 10)
            && !target_sub_type
        ) {
            return 'fa-clock-o';
        }

        else if(
            target_type.id === parseInt(REACT_APP_APPOINTMENT_NOTIFICATION_TARGET_TYPE_ID, 10)
            && feedbackTargetSubTypes.indexOf(target_sub_type.id) > -1
        ) {
            return 'fa-pencil-square-o';
        }

        else if(
            target_type.id === parseInt(REACT_APP_APPOINTMENT_NOTIFICATION_TARGET_TYPE_ID, 10)
            && target_sub_type.id === parseInt(REACT_APP_CHANGED_APPOINTMENT_TARGET_SUB_TYPE_ID, 10)
        ) {
            return 'fa-refresh';
        }

        else if(
            target_type.id === parseInt(REACT_APP_INVOICE_NOTIFICATION_TARGET_TYPE_ID, 10)
            && !target_sub_type
        ) {
            return 'fa-gbp';
        }

        else if(
            target_type.id === parseInt(REACT_APP_USER_NOTIFICATION_TARGET_TYPE_ID, 10)
            && target_sub_type.id === parseInt(REACT_APP_VETTED_USER_NOTIFICATION_TARGET_TYPE_ID, 10)
        ) {
            return 'fa-check';
        }

        else if(
            target_type.id === parseInt(REACT_APP_DOCUMENT_NOTIFICATION_TARGET_TYPE_ID, 10)
            && !target_sub_type
        ) {
            return 'fa-file';
        }
    }

    return null;
};

export const getNotificationTitle = notification => {
    if(
        !notification
        || !notification.target
        || !notification.target_type
    ) {
        return 'Deleted';
    }

    const { target, target_type, target_sub_type } = notification;

    // Invitation notification
    if(target_type.id === parseInt(REACT_APP_INVITATION_NOTIFICATION_TARGET_TYPE_ID, 10)) {
        return 'Invitation';
    }

    // Confirmed Appointment notification
    if(
        target_type.id === parseInt(REACT_APP_APPOINTMENT_NOTIFICATION_TARGET_TYPE_ID, 10)
        && !target_sub_type
    ) {
        return 'Appointment';
    }

    // Feedback notification
    if(
        target_type.id === parseInt(REACT_APP_APPOINTMENT_NOTIFICATION_TARGET_TYPE_ID, 10)
        && feedbackTargetSubTypes.indexOf(target_sub_type.id) > -1
    ) {
        return 'Feedback';
    }

    // Changed Appointment notification
    if(
        target_type.id === parseInt(REACT_APP_APPOINTMENT_NOTIFICATION_TARGET_TYPE_ID, 10)
        && target_sub_type.id === parseInt(REACT_APP_CHANGED_APPOINTMENT_TARGET_SUB_TYPE_ID, 10)
    ) {
        return 'Appointment Changed';
    }

    // Invoice notification
    if(
        target_type.id === parseInt(REACT_APP_INVOICE_NOTIFICATION_TARGET_TYPE_ID, 10)
        && !target_sub_type
    ) {
        return 'New Invoice';
    }

    // User Vetted notification
    if(
        target_type.id === parseInt(REACT_APP_USER_NOTIFICATION_TARGET_TYPE_ID, 10)
        && target_sub_type.id === parseInt(REACT_APP_VETTED_USER_NOTIFICATION_TARGET_TYPE_ID, 10)
    ) {
        return 'Vetted';
    }

    // Document notification
    if(
        target_type.id === parseInt(REACT_APP_DOCUMENT_NOTIFICATION_TARGET_TYPE_ID, 10)
        && !target_sub_type
    ) {
        const { document_category } = target;
        if(!document_category) {
            return null;
        }

        return 'New Document';
    }

    if (target_sub_type && target_sub_type.id === parseInt(REACT_APP_SMS_MESSAGE_NOTIFICATION_TARGET_TYPE_ID, 10)) {
        return `Message`;
    }

    return 'Deleted';
};

export const getNotificationText = notification => {
    if(
        !notification
        || !notification.target
        || !notification.target_type
    ) {
        return '[Deleted]';
    }

    const { target, target_type, target_sub_type } = notification;

    // Invitation notification
    if(target_type.id === parseInt(REACT_APP_INVITATION_NOTIFICATION_TARGET_TYPE_ID, 10)) {
        const { viewing } = target;
        let text = ``;
        if(viewing) {
            text += `You have been invited to viewing VV${notification.target.viewing.id}`;

            if (viewingOccursOnDateAndTime(viewing) && viewing.viewber_date) {
                text += ' on '+getFullDateText(viewing.viewber_date);
                text += ' at '+getTimeText(viewing.viewber_date);
            } else if (viewing.viewber_date) {
                text += ' on '+getFullDateText(viewing.viewber_date);
            }

            if(viewing.property) {
                text = text + ' at '+viewing.property.postcode;
            }
        } else {
            text = text + 'No data available.';
        }

        return text;
    }

    // Confirmed Appointment notification
    if(
        target_type.id === parseInt(REACT_APP_APPOINTMENT_NOTIFICATION_TARGET_TYPE_ID, 10)
        && !target_sub_type
    ) {
        const { viewing } = target;
        let text = ``;
        if(viewing) {
            text += `You are the confirmed viewber for viewing VV${notification.target.viewing.id}`;

            if (viewingOccursOnDateAndTime(viewing) && viewing.viewber_date) {
                text += ' on '+getFullDateText(viewing.viewber_date);
                text += ' at '+getTimeText(viewing.viewber_date);
            } else if (viewing.viewber_date) {
                text += ' on '+getFullDateText(viewing.viewber_date);
            }

            if(viewing.property) {
                text = text + ' at '+viewing.property.postcode;
            }
        } else {
            text = text + 'No data available.';
        }

        return text;
    }

    // Feedback notification
    if(
        target_type.id === parseInt(REACT_APP_APPOINTMENT_NOTIFICATION_TARGET_TYPE_ID, 10)
        && feedbackTargetSubTypes.indexOf(target_sub_type.id) > -1
    ) {
        const { viewing } = target;
        let text = ``;
        if(viewing) {
            text += `Please submit your feedback for viewing VV${notification.target.viewing.id}`;

            if (viewingOccursOnDateAndTime(viewing) && viewing.viewber_date) {
                text += ' on '+getFullDateText(viewing.viewber_date);
                text += ' at '+getTimeText(viewing.viewber_date);
            } else if (viewing.viewber_date) {
                text += ' on '+getFullDateText(viewing.viewber_date);
            }

            if(viewing.property) {
                text = text + ' at '+viewing.property.postcode;
            }
        }

        return text;
    }

    // Changed Appointment notification
    if(
        target_type.id === parseInt(REACT_APP_APPOINTMENT_NOTIFICATION_TARGET_TYPE_ID, 10)
        && target_sub_type.id === parseInt(REACT_APP_CHANGED_APPOINTMENT_TARGET_SUB_TYPE_ID, 10)
    ) {
        const { viewing } = target;
        let text = ``;

        if (viewing) {
            text += `Please re-confirm your availability for viewing VV${notification.target.viewing.id}`;

            if (viewingOccursOnDateAndTime(viewing) && viewing.viewber_date) {
                text += ' on '+getFullDateText(viewing.viewber_date);
                text += ' at '+getTimeText(viewing.viewber_date);
            } else if (viewing.viewber_date) {
                text += ' on '+getFullDateText(viewing.viewber_date);
            }

            if (viewing.property) {
                text = text + ' at '+viewing.property.postcode;
            }
        }

        return text;
    }

    // Invoice notification
    if(
        target_type.id === parseInt(REACT_APP_INVOICE_NOTIFICATION_TARGET_TYPE_ID, 10)
        && !target_sub_type
    ) {
        return `A new invoice has been issued V${notification.target_id}`;
    }

    // User Vetted notification
    if (
        target_type.id === parseInt(REACT_APP_USER_NOTIFICATION_TARGET_TYPE_ID, 10)
        && target_sub_type.id === parseInt(REACT_APP_VETTED_USER_NOTIFICATION_TARGET_TYPE_ID, 10)
    ) {
        return `Congratulations! You are now a vetted viewber!`;
    }

    // Templated SMS message
    if (target_sub_type && target_sub_type.id === parseInt(REACT_APP_SMS_MESSAGE_NOTIFICATION_TARGET_TYPE_ID, 10)) {
        const { decoded_payload } = notification
        let message = '';
        if (typeof decoded_payload === 'object' && decoded_payload.hasOwnProperty('client_tile_content')) {
            message = decoded_payload.client_tile_content
        }
        return message;
    }

    // Document notification
    if (
        target_type.id === parseInt(REACT_APP_DOCUMENT_NOTIFICATION_TARGET_TYPE_ID, 10)
        && !target_sub_type
    ) {
        if (!target.document_category) {
            return 'A new document version is available';
        }
        return `A new version of the ${target.document_category.name} is available`;
    }

    if (target_type.id === parseInt(REACT_APP_APPOINTMENT_NOTIFICATION_TARGET_TYPE_ID, 10)) {
        return '[Appointment no longer valid]';
    }
    
    if (target_type.id === parseInt(REACT_APP_INVITATION_NOTIFICATION_TARGET_TYPE_ID, 10)) {
        return '[Invitation no longer valid]';
    }

    return '[Deleted]';
};
